import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { apiUrl } from 'config';
import { randomTimeout } from 'utils/randomTimeout';
import { User } from './PageWrapper';

type StandsDto = {
  stands: {
    standName: string;
    reservedBy: string | null;
    reservedAt: string | null;
  }[];
};

type ReserveStandRequestDto = {
  standName: string;
};

type FreeStandRequestDto = {
  standName: string;
};

type Stand = {
  standName: string;
  reservedBy: string | null;
  reservedAt: string | null;
};

export type StandReservationPageProps = {
  user: User;
  setLoading: (loading: boolean) => void;
};

export const StandReservationPage = ({ user, setLoading }: StandReservationPageProps) => {
  const [stands, setStands] = useState<Stand[]>([]);

  useEffect(() => {
    setLoading(true);

    randomTimeout(() =>
      axios
        .request<StandsDto>({
          method: 'GET',
          baseURL: apiUrl,
          url: 'stand',
          headers: { Authorization: `Bearer ${user.accessToken}` }
        })
        .then(async (response) => {
          const data = response.data;
          setStands(
            data.stands.map(
              (stand) =>
                ({
                  standName: stand.standName,
                  reservedBy: stand.reservedBy,
                  reservedAt: stand.reservedAt
                }) as Stand
            )
          );
        })
        .catch(async (error) => {
          alert(
            error.response
              ? `Request failed with status ${error.response.status}`
              : `Error during a request: ${error.message}`
          );
        })
        .finally(() => setLoading(false))
    );
  }, [user.accessToken, setLoading]);

  const reserveStand = useCallback(
    (standName: string) => {
      setLoading(true);

      randomTimeout(() =>
        axios
          .request<StandsDto, AxiosResponse<StandsDto>, ReserveStandRequestDto>({
            method: 'PATCH',
            baseURL: apiUrl,
            url: 'stand',
            headers: { Authorization: `Bearer ${user.accessToken}` },
            data: { standName }
          })
          .then(async (response) => {
            const data = response.data;
            setStands(
              data.stands.map(
                (stand) =>
                  ({
                    standName: stand.standName,
                    reservedBy: stand.reservedBy,
                    reservedAt: stand.reservedAt
                  }) as Stand
              )
            );
          })
          .catch(async (error) => {
            alert(
              error.response
                ? `Request failed with status ${error.response.status}`
                : `Error during a request: ${error.message}`
            );
          })
          .finally(() => setLoading(false))
      );
    },
    [user.accessToken, setLoading]
  );

  const freeStand = useCallback(
    (standName: string) => {
      setLoading(true);

      randomTimeout(() =>
        axios
          .request<StandsDto, AxiosResponse<StandsDto>, FreeStandRequestDto>({
            method: 'DELETE',
            baseURL: apiUrl,
            url: 'stand',
            headers: { Authorization: `Bearer ${user.accessToken}` },
            data: { standName }
          })
          .then(async (response) => {
            const data = response.data;
            setStands(
              data.stands.map(
                (stand) =>
                  ({
                    standName: stand.standName,
                    reservedBy: stand.reservedBy,
                    reservedAt: stand.reservedAt
                  }) as Stand
              )
            );
          })
          .catch(async (error) => {
            alert(
              error.response
                ? `Request failed with status ${error.response.status}`
                : `Error during a request: ${error.message}`
            );
          })
          .finally(() => setLoading(false))
      );
    },
    [user.accessToken, setLoading]
  );

  return (
    <div className="StandReservationPage">
      <div className="StandReservations">
        {stands.map((stand) => (
          <div key={stand.standName} className="StandReservation">
            <h3>{stand.standName}</h3>
            <hr />
            <div className="StandReservationText">
              {stand.reservedAt && stand.reservedBy ? (
                <>
                  <div>{stand.reservedBy}</div>
                  <div>{new Date(stand.reservedAt).toLocaleString()}</div>
                </>
              ) : (
                <p>
                  Stand is free
                  <br />
                  Feel free to reserve
                  <br />
                </p>
              )}
            </div>
            <div>
              <button disabled={stand.reservedBy === user.name} onClick={() => reserveStand(stand.standName)}>
                Reserve
              </button>
              <button disabled={!stand.reservedAt || !stand.reservedBy} onClick={() => freeStand(stand.standName)}>
                Free
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
