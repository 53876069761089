import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { SHA256, enc } from 'crypto-js';
import { apiUrl } from 'config';
import { randomTimeout } from 'utils/randomTimeout';

type ChangePasswordRequestDto = {
  code: string;
  newPassword: string;
};

type CheckChangePasswordCodeRequestDto = {
  code: string;
};

type CheckChangePasswordCodeResponseDto = {
  valid: boolean;
};

export type ChangePasswordPageProps = {
  refreshUser: () => unknown;
  setLoading: (loading: boolean) => unknown;
};

export const ChangePasswordPage = ({ setLoading }: ChangePasswordPageProps) => {
  const [searchParams] = useSearchParams();
  const [formState, setFormState] = useState<{ newPassword: string; newPasswordConfirmation: string }>({
    newPassword: '',
    newPasswordConfirmation: ''
  });
  const [linkExpired, setLinkExpired] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);

      randomTimeout(() =>
        axios
          .request<void, AxiosResponse<void>, ChangePasswordRequestDto>({
            method: 'PATCH',
            baseURL: apiUrl,
            url: 'user',
            data: {
              code: searchParams.get('code') ?? '',
              newPassword: SHA256(formState.newPassword).toString(enc.Base64)
            }
          })
          .then(async () => {
            alert('Successfully changed your password');
            navigate('/');
          })
          .catch(async (error) => {
            alert(
              error.response
                ? `Request failed with status ${error.response.status}`
                : `Error during a request: ${error.message}`
            );
          })
          .finally(() => setLoading(false))
      );
    },
    [searchParams, formState, setLoading, navigate]
  );

  useEffect(() => {
    const codeFromQueryString = searchParams.get('code') ?? '';
    const regexExp = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    if (!regexExp.test(codeFromQueryString)) {
      navigate('/');
      return;
    }

    setLoading(true);

    randomTimeout(() =>
      axios
        .request<
          CheckChangePasswordCodeResponseDto,
          AxiosResponse<CheckChangePasswordCodeResponseDto>,
          CheckChangePasswordCodeRequestDto
        >({
          method: 'POST',
          baseURL: apiUrl,
          url: 'auth/checkChangePasswordCode',
          data: {
            code: searchParams.get('code') ?? ''
          }
        })
        .then((response) => {
          if (!response.data.valid) setLinkExpired(true);
        })
        .catch(async (error) => {
          navigate('/');
          alert(
            error.response
              ? `Request failed with status ${error.response.status}`
              : `Error during a request: ${error.message}`
          );
        })
        .finally(() => setLoading(false))
    );
  }, [searchParams, setLoading, navigate]);

  return (
    <div className="ChangePasswordPage">
      {!linkExpired ? (
        <form onSubmit={handleSubmit}>
          <h2>Change password</h2>
          <label htmlFor="newPassword">New password</label>
          <input
            type="password"
            placeholder="Enter new password"
            name="newPassword"
            value={formState.newPassword}
            onChange={handleChange}
            required
          />
          <label htmlFor="newPasswordConfirmation">New password confirmation</label>
          <input
            type="password"
            placeholder="Confirm new password"
            name="newPasswordConfirmation"
            value={formState.newPasswordConfirmation}
            onChange={handleChange}
            required
          />
          <button type="submit" disabled={formState.newPassword !== formState.newPasswordConfirmation}>
            Change password
          </button>
        </form>
      ) : (
        <>
          Link expired! Go back to&nbsp;
          <span className="Colored Pointer" onClick={() => navigate('/')}>
            home
          </span>
        </>
      )}
    </div>
  );
};
