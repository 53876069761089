import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { SHA256, enc } from 'crypto-js';
import { apiUrl } from 'config';
import { randomTimeout } from 'utils/randomTimeout';

type LoginRequestDto = {
  email: string;
  password: string;
};

type LoginResponseDto = {
  accessToken: string;
};

export type LoginPageProps = {
  refreshUser: () => unknown;
  setLoading: (loading: boolean) => unknown;
};

export const LoginPage = ({ refreshUser, setLoading }: LoginPageProps) => {
  const [formState, setFormState] = useState<{ email: string; password: string }>({ email: '', password: '' });
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);

      randomTimeout(() =>
        axios
          .request<LoginResponseDto, AxiosResponse<LoginResponseDto>, LoginRequestDto>({
            method: 'POST',
            baseURL: apiUrl,
            url: 'auth',
            data: {
              email: formState.email,
              password: SHA256(formState.password).toString(enc.Base64)
            }
          })
          .then(async (response) => {
            const data = response.data;
            localStorage.setItem('accessToken', data.accessToken);
            refreshUser();
          })
          .catch(async (error) => {
            alert(
              error.response
                ? `Request failed with status ${error.response.status}`
                : `Error during a request: ${error.message}`
            );
          })
          .finally(() => setLoading(false))
      );
    },
    [formState, refreshUser, setLoading]
  );

  return (
    <div className="LoginPage">
      <form onSubmit={handleSubmit}>
        <h2>Login</h2>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          placeholder="Enter email"
          name="email"
          value={formState.email}
          onChange={handleChange}
          required
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          placeholder="Enter password"
          name="password"
          value={formState.password}
          onChange={handleChange}
          required
        />
        <span className="Colored Pointer" onClick={() => navigate('/forgotPassword')}>
          Forgot password?
        </span>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};
