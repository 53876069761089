import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { apiUrl } from 'config';
import { randomTimeout } from 'utils/randomTimeout';

type ForgotPasswordRequestDto = {
  email: string;
};

export type ForgotPasswordPageProps = {
  refreshUser: () => unknown;
  setLoading: (loading: boolean) => unknown;
};

export const ForgotPasswordPage = ({ setLoading }: ForgotPasswordPageProps) => {
  const [formState, setFormState] = useState<{ email: string }>({ email: '' });
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);

      randomTimeout(() =>
        axios
          .request<void, AxiosResponse<void>, ForgotPasswordRequestDto>({
            method: 'POST',
            baseURL: apiUrl,
            url: 'auth/changePassword',
            data: {
              email: formState.email
            }
          })
          .then(() => {
            setRequestSent(true);
          })
          .catch(async (error) => {
            alert(
              error.response
                ? `Request failed with status ${error.response.status}`
                : `Error during a request: ${error.message}`
            );
          })
          .finally(() => setLoading(false))
      );
    },
    [formState, setLoading]
  );

  return (
    <div className="ForgotPasswordPage">
      {!requestSent ? (
        <form onSubmit={handleSubmit}>
          <h2>Change password</h2>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={formState.email}
            onChange={handleChange}
            required
          />
          <span className="Colored Pointer" onClick={() => navigate('/login')}>
            Remember password?
          </span>
          <button type="submit">Send request</button>
        </form>
      ) : (
        <>
          Request sent, please check your email. Go back to&nbsp;
          <span className="Colored Pointer" onClick={() => navigate('/')}>
            home
          </span>
        </>
      )}
    </div>
  );
};
