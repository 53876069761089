import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

// Полифилл для :focus-visible
import 'focus-visible';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-subtle.css';
import 'app/styles/index.scss';

import reportWebVitals from 'reportWebVitals';
import { StandReservationPage } from 'app/pages/StandReservationPage';
import { PageWrapper } from 'app/pages/PageWrapper';
import { LoginPage } from 'app/pages/LoginPage';
import { ChangePasswordPage } from 'app/pages/ChangePassword';
import { ForgotPasswordPage } from 'app/pages/ForgotPassword';
import { StandActionLogsPage } from 'app/pages/StandActionLogsPage';
import { GRAPHANA_LINK } from 'app/constants';

const tabs = [
  { name: 'Reservation', route: '/stands' },
  { name: 'Statistics', route: '/standActionLogs' },
  { name: 'Logs', onClick: () => window.open(GRAPHANA_LINK), customClass: 'LogsTab' }
];

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="login" element={<PageWrapper InnerPage={LoginPage} needAuth={false} redirectTo="/" />} />
      <Route
        path="forgotPassword"
        element={<PageWrapper InnerPage={ForgotPasswordPage} needAuth={false} redirectTo="/" />}
      />
      <Route
        path="changePassword"
        element={<PageWrapper InnerPage={ChangePasswordPage} needAuth={false} redirectTo="/" />}
      />
      <Route
        path="stands"
        element={
          <PageWrapper
            InnerPage={StandReservationPage}
            needAuth={true}
            redirectTo="/login"
            tabs={tabs}
            activeTab="Reservation"
          />
        }
      />
      <Route
        path="standActionLogs"
        element={
          <PageWrapper
            InnerPage={StandActionLogsPage}
            needAuth={true}
            redirectTo="/login"
            tabs={tabs}
            activeTab="Statistics"
          />
        }
      />
      <Route path="" element={<Navigate to={'/stands'} replace />} />
      <Route path="*" element={<Navigate to={'/stands'} replace />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
