function env<T = string | undefined>(variable: string | undefined, defaultValue?: T) {
  return typeof variable === 'undefined' ? defaultValue : variable;
}

export const apiUrl = env(process.env.REACT_APP_API_URL, '/api');

export const hostURl = env(process.env.PUBLIC_URL, '/');

export const isProduction = () => process.env.NODE_ENV === 'production';
export const isDevelopment = () => process.env.NODE_ENV === 'development';
