import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from 'config';
import { randomTimeout } from 'utils/randomTimeout';

type UserDto = {
  email: string;
  name: string;
};

export type User = {
  email: string;
  name: string;
  accessToken: string;
};

type PageWrapperProps = (
  | {
      InnerPage: React.ComponentType<{
        user: User;
        setLoading: (loading: boolean) => unknown;
      }>;
      needAuth: true;
    }
  | {
      InnerPage: React.ComponentType<{
        refreshUser: () => unknown;
        setLoading: (loading: boolean) => unknown;
      }>;
      needAuth: false;
    }
) & {
  redirectTo: string;
  tabs?: { name: string; route?: string; onClick?: () => unknown; customClass?: string }[];
  activeTab?: string;
};

export const PageWrapper = ({ InnerPage, needAuth, redirectTo, tabs, activeTab }: PageWrapperProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const navigate = useNavigate();

  const refreshUser = useCallback(() => {
    setLoadingUser(true);

    randomTimeout(() => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken === null) {
        setLoadingUser(false);
        return;
      }

      axios
        .request<UserDto>({
          method: 'GET',
          baseURL: apiUrl,
          url: 'user',
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(async (response) => {
          const data = response.data;
          setUser({
            name: data.name,
            email: data.email,
            accessToken
          });
        })
        .catch(async (error) => {
          alert(
            error.response
              ? `Request failed with status ${error.response.status}`
              : `Error during a request: ${error.message}`
          );
          localStorage.removeItem('accessToken');
          setUser(null);
        })
        .finally(() => setLoadingUser(false));
    });
  }, []);

  useEffect(refreshUser, [refreshUser]);

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    setUser(null);
  }, []);

  return (
    <>
      <header>
        <h1>Violet Stands Manager</h1>
        {user && (
          <div className="User">
            <div>{user.email}</div>
            <button onClick={logout}>Logout</button>
          </div>
        )}
      </header>
      {activeTab !== undefined && tabs !== undefined && tabs.map(({ name }) => name).includes(activeTab) && (
        <nav>
          {tabs.map(({ name, route, onClick, customClass }, i) => (
            <div
              key={i}
              className={[name === activeTab ? 'Colored' : '', customClass ?? ''].join(' ')}
              onClick={() => name !== activeTab && (route ? navigate(route) : onClick && onClick())}>
              {name}
            </div>
          ))}
        </nav>
      )}
      <main>
        {(loadingUser || loading) && <div className="Loader">Loading...</div>}
        {!loadingUser &&
          (needAuth ? (
            user ? (
              <InnerPage user={user} setLoading={setLoading} />
            ) : (
              <Navigate to={redirectTo} replace />
            )
          ) : user === null ? (
            <InnerPage refreshUser={refreshUser} setLoading={setLoading} />
          ) : (
            <Navigate to={redirectTo} replace />
          ))}
      </main>
    </>
  );
};
